export var bundleSizes = [
  {
    DESC: "1X4:",
    Thickness: 1,
    Width: 4,
    Wide: 13,
    High: 32,
    PCS: 416,
  },
  {
    DESC: "1X6:",
    Thickness: 1,
    Width: 6,
    Wide: 8,
    High: 32,
    PCS: 256,
  },
  {
    DESC: "1X8:",
    Thickness: 1,
    Width: 8,
    Wide: 6,
    High: 32,
    PCS: 192,
  },
  {
    DESC: "1X10:",
    Thickness: 1,
    Width: 10,
    Wide: 5,
    High: 32,
    PCS: 160,
  },
  {
    DESC: "1X12:",
    Thickness: 1,
    Width: 12,
    Wide: 4,
    High: 32,
    PCS: 128,
  },
  {
    DESC: "5/4X4:",
    Thickness: 1.25,
    Width: 4,
    Wide: 13,
    High: 24,
    PCS: 312,
  },
  {
    DESC: "5/4X6:",
    Thickness: 1.25,
    Width: 6,
    Wide: 8,
    High: 24,
    PCS: 192,
  },
  {
    DESC: "2X2:",
    Thickness: 2,
    Width: 2,
    Wide: 16,
    High: 64,
    PCS: 1024,
  },
  {
    DESC: "2X3:",
    Thickness: 2,
    Width: 3,
    Wide: 16,
    High: 16,
    PCS: 256,
  },
  {
    DESC: "2X4:",
    Thickness: 2,
    Width: 4,
    Wide: 13,
    High: 16,
    PCS: 208,
  },
  {
    DESC: "2X6:",
    Thickness: 2,
    Width: 6,
    Wide: 8,
    High: 16,
    PCS: 128,
  },
  {
    DESC: "2X8:",
    Thickness: 2,
    Width: 8,
    Wide: 6,
    High: 16,
    PCS: 96,
  },
  {
    DESC: "2X10:",
    Thickness: 2,
    Width: 10,
    Wide: 5,
    High: 16,
    PCS: 80,
  },
  {
    DESC: "2X12:",
    Thickness: 2,
    Width: 12,
    Wide: 4,
    High: 16,
    PCS: 64,
  },
  {
    DESC: "2X14:",
    Thickness: 2,
    Width: 14,
    Wide: 3,
    High: 16,
    PCS: 48,
  },
  {
    DESC: "3X4:",
    Thickness: 3,
    Width: 4,
    Wide: 13,
    High: 10,
    PCS: 130,
  },
  {
    DESC: "3X6:",
    Thickness: 3,
    Width: 6,
    Wide: 8,
    High: 10,
    PCS: 80,
  },
  {
    DESC: "3X8:",
    Thickness: 3,
    Width: 8,
    Wide: 6,
    High: 10,
    PCS: 60,
  },
  {
    DESC: "3X10:",
    Thickness: 3,
    Width: 10,
    Wide: 5,
    High: 10,
    PCS: 50,
  },
  {
    DESC: "3X12:",
    Thickness: 3,
    Width: 12,
    Wide: 4,
    High: 10,
    PCS: 40,
  },
  {
    DESC: "4X4:",
    Thickness: 4,
    Width: 4,
    Wide: 13,
    High: 7,
    PCS: 91,
  },
  {
    DESC: "4X6:",
    Thickness: 4,
    Width: 6,
    Wide: 8,
    High: 7,
    PCS: 56,
  },
  {
    DESC: "4X8:",
    Thickness: 4,
    Width: 8,
    Wide: 6,
    High: 7,
    PCS: 42,
  },
  {
    DESC: "4X10:",
    Thickness: 4,
    Width: 10,
    Wide: 5,
    High: 7,
    PCS: 35,
  },
  {
    DESC: "4X12:",
    Thickness: 4,
    Width: 12,
    Wide: 4,
    High: 7,
    PCS: 28,
  },
  {
    DESC: "4X14:",
    Thickness: 4,
    Width: 14,
    Wide: 3,
    High: 7,
    PCS: 21,
  },
  {
    DESC: "4X16:",
    Thickness: 4,
    Width: 16,
    Wide: 3,
    High: 7,
    PCS: 21,
  },
  {
    DESC: "6X6:",
    Thickness: 6,
    Width: 6,
    Wide: 8,
    High: 4,
    PCS: 32,
  },
  {
    DESC: "6X8:",
    Thickness: 6,
    Width: 8,
    Wide: 6,
    High: 4,
    PCS: 24,
  },
  {
    DESC: "6X10:",
    Thickness: 6,
    Width: 10,
    Wide: 5,
    High: 4,
    PCS: 20,
  },
  {
    DESC: "6X12:",
    Thickness: 6,
    Width: 12,
    Wide: 4,
    High: 4,
    PCS: 16,
  },
  {
    DESC: "6X14:",
    Thickness: 6,
    Width: 14,
    Wide: 3,
    High: 4,
    PCS: 12,
  },
];

