import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import style from "./style.css";
import { tableGen } from "./tableGen";
import { tableCal } from "./tableCal";
import { tableJoin } from "./tableJoin";

const Table = ({ val }) => {
  var check = false;

  function cellChange(event) {
    var id = event.target.id;
    var value = event.target.value;
    var tableTemp = [...tableCal.cal(table, id, value)];

    setTable(tableTemp);
  }

  function updateInterpreter(inputData, table) {
    var [tableTemp, listTemp] = tableJoin.update(inputData, table);

    var id = "";
    var value = "";

    for (var i = 0; i < listTemp.length; i++) {
      id = `id_${i + 2}_${0}`;
      value = parseFloat(listTemp[i].thickness);

      tableTemp = [...tableCal.cal(tableTemp, id, value)];
    }

    return tableTemp;
  }

  function intialized(inputData) {
    var table = tableGen.gen(30);

    if (inputData != undefined) {
      table = updateInterpreter(inputData, table);
    }

    return table;
  }

  const [table, setTable] = useState(intialized(val.inputData));
  const [input, setInput] = useState(val.inputData);

  if (input != val.inputData) {
    setInput(val.inputData);
    setTable(intialized(val.inputData));
  }

  var renderRow = function (datnum, i) {
    var idVar = "";

    return (
      <tr>
        {datnum.map((dat, j) => {
          idVar = `id_${dat.x}_${dat.y}`;

          if (dat.type == "input") {
            return (
              <td>
                <input
                  id={idVar}
                  onChange={cellChange}
                  value={dat.val}
                  placeholder={dat.val}
                ></input>
              </td>
            );
          } else if (
            dat.name == "Total" &&
            dat.type != "cal" &&
            dat.val != "Total"
          ) {
            return <td id={idVar}>{dat.val}</td>;
          } else if (
            dat.name == "Header" ||
            (dat.name == "Total" && dat.val == "Total")
          ) {
            return (
              <td
                style="background-color: #ADD8E6; font-weight: bold; text-align: center;"
                id={idVar}
              >
                {dat.val}
              </td>
            );
          } else {
            return (
              <td style="background-color: #D3D3D3;" id={idVar}>
                {dat.val}
              </td>
            );
          }
        })}
      </tr>
    );
  };

  return (
    <div>
      <h2>Lumber:</h2>
      <ul>
        <li>T = Thickness (in)</li>
        <li>W = Width (in)</li>
        <li>L = Length (ft)</li>
        <li>B = Bundles (EA)</li>
        <li>BA = Bundle Amounts (EA)</li>
      </ul>
      <button
        onClick={() => {
          window.location.reload();
        }}
      >
        Clear Table
      </button>
      <table class="tablemobile">
        {table.map((datum, i) => {
          return renderRow(datum, i);
        })}
      </table>
    </div>
  );
};

export default Table;

