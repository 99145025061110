import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import style from "./style.css";

const Info = () => {
  return (
    <div>
      <h1 class={style.header}>Reference</h1>
      <p>
        Definition - The board foot or board-foot is a unit of measurement for
        the volume of lumber in the United States and Canada. It equals the
        volume of a one-foot length of a board, one foot wide and one
        inch thick
      </p>
      <p>Board Feet (Bdft) = (thickness" x width" x length")/144</p>
      <p>
        Board Feet (Bdft) = (thickness" x width" x length')/12 : If length in
        Feet
      </p>
    </div>
  );
};

export default Info;

