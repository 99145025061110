import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import style from "./style.css";

const Header = () => {
  return (
    <div>
      <h1 class={style.header}>BDFT Calculator</h1>
      <p>
        This is a simple tool designed to help determine lumber costs, designed
        by and for lumber buyers and users.
      </p>
    </div>
  );
};

export default Header;

