import { obj } from "./Data/obj";

var round = (num) => {
  return Math.round(num * 100 + Number.EPSILON) / 100;
};

//Need to delete rows and when cells delete update the cal columns
//Hidden Field where a text is formed and then you can copy that data to text, also need to a save function

export var tableCal = {
  cal: (tableArray, id, val) => {
    var cell = tableCal.parseXY(id);

    tableArray[cell.x][cell.y].val = val;
    cell = tableArray[cell.x][cell.y];
    cell.ref = tableCal.nameSearch(cell);
    cell.calRef = tableCal.calSearch(cell);
    cell.valRef = tableCal.valRef(cell, tableArray);
    tableArray = tableCal.solve(cell, tableArray, id);

    //Second change

    for (var i = 0; i < 10; i++) {
      cell.valRef = tableCal.valRef(cell, tableArray);
      tableArray = tableCal.solve(cell, tableArray, id);
    }

    tableArray = tableCal.calTotals(tableArray);

    return tableArray;
  },
  checkVals: (checkVars) => {
    var check = true;
    for (var i = 0; i < checkVars.length; i++) {
      if (!parseFloat(checkVars[i])) {
        check = false;
      }
    }

    return check;
  },
  parseXY: (id) => {
    var cord = {};

    var strs = id.split("_");
    cord.x = parseFloat(strs[1]);
    cord.y = parseFloat(strs[2]);

    return cord;
  },
  nameSearch: (cell) => {
    for (var keys in obj) {
      if (obj[keys].name == cell.name) {
        obj[keys].key = keys;
        return obj[keys];
      }
    }
  },
  calSearch: (cell) => {
    //Seeing what other obj keys have this value in a function
    var calData = [];
    for (var keys in obj) {
      if (obj[keys].calVar != undefined) {
        calData.push(obj[keys]);
      }
    }

    var calRef = [];
    var calVar = [];
    var j = [];
    var check = false;

    for (var i = 0; i < calData.length; i++) {
      calVar = calData[i].calVar;

      for (j = 0; j < calVar.length; j++) {
        if (calVar[j] == cell.ref.key) {
          check = true;
        }
      }

      if (check) {
        calRef.push(calData[i]);
      }

      check = false;
    }

    return calRef;
  },
  valRef: (cell, tableArray) => {
    var calRef = cell.calRef;
    var calVals = [];
    var calValsTemp = [];
    var val = "";
    var j = 0;

    for (var i = 0; i < calRef.length; i++) {
      for (j = 0; j < calRef[i].calVar.length; j++) {
        val = tableArray[cell.x][obj[calRef[i].calVar[j]].num].val;
        calValsTemp.push(val);
      }

      calVals.push(calValsTemp);
      calValsTemp = [];
    }

    return calVals;
  },
  solve: (cell, tableArray, id) => {
    var j = 0;
    var check = true;
    var val = "";

    for (var i = 0; i < cell.calRef.length; i++) {
      for (j = 0; j < cell.valRef[i].length; j++) {
        if (cell.valRef[i][j] == "") {
          check = false;
        } else {
          cell.valRef[i][j] = parseFloat(cell.valRef[i][j]);
        }
      }

      if (check) {
        val = cell.calRef[i].cal(cell.valRef[i], id);
        tableArray[cell.x][obj[cell.calRef[i].key].num].val = `${val}`;
      }

      check = true;
    }

    return tableArray;
  },
  calTotals: (tableArray) => {
    //hard coded hack
    var length = tableArray[0].length;
    var nums = [length - 4, length - 1, length - 3];
    var totals = [0, 0];

    var i = 0;
    var j = 0;

    for (i = 2; i < tableArray[0].length; i++) {
      if (parseFloat(tableArray[i][nums[0]].val)) {
        totals[0] = totals[0] + parseFloat(tableArray[i][nums[0]].val);
      }

      if (parseFloat(tableArray[i][nums[1]].val)) {
        totals[1] = totals[1] + parseFloat(tableArray[i][nums[1]].val);
      }
    }

    var avgMbft = "";

    if (totals[0] && totals[1]) {
      avgMbft = totals[1] / (totals[0] / 1000);
    }

    tableArray[0][nums[0]].val = `${round(totals[0])}`;
    tableArray[0][nums[1]].val = `${round(totals[1])}`;
    tableArray[0][nums[2]].val = `${round(avgMbft)}`;

    return tableArray;
  },
};

