import { bundleSizes } from "./bundleSizes";

function createBundlesTerms() {
  var list = [];

  var main = "";
  var desc = "";
  for (var i = 0; i < bundleSizes.length; i++) {
    main = `${bundleSizes[i].Thickness}X${bundleSizes[i].Width}`;
    desc = bundleSizes[i].DESC.replace(':','');

    list.push({
      id: desc,
      text: desc,
      type: "main",
    });

    if (main != desc) {
      list.push({
        id: desc,
        text: main,
        type: "main",
      });
    }
  }

  return list;
}

var termsList = createBundlesTerms();

export var terms = termsList;

//Main is the thickness and Width together
//Length is the length
//bundle quantities
//price
