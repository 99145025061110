//Define header colors in obj
import { bundleSizes } from "./bundleSizes";

var round = (num) => {
  return Math.round(num * 100 + Number.EPSILON) / 100;
};

export var obj = {
  thickness: {
    key: "thickness",
    name: "T",
    val: "",
    type: "input",
    num: 0,
  },
  width: {
    key: "width",
    name: "W",
    val: "",
    type: "input",
    num: 1,
  },
  length: {
    key: "length",
    name: "L",
    val: "",
    type: "input",
    num: 2,
  },
  bundles: {
    key: "bundles",
    name: "B",
    val: "",
    type: "input",
    num: 3,
  },
  bundlesAmount: {
    key: "bundlesAmount",
    name: "BA",
    val: "",
    type: "input",
    num: 4,
    calVar: ["thickness", "width", "length"],
    cal: (val, id) => {
      var thickness = val[0];
      var width = val[1];
      var length = val[2];

      var bundleReturn = {};
      var check = false;
      for (var i = 0; i < bundleSizes.length; i++) {
        if (
          bundleSizes[i].Thickness == thickness &&
          bundleSizes[i].Width == width
        ) {
          bundleReturn = bundleSizes[i];
          check = true;
          break;
        }
      }

      if (check) {
        return bundleReturn.PCS;
      } else {
        return "";
      }
    },
  },
  qty: {
    key: "qty",
    name: "Qty",
    val: "",
    type: "cal",
    num: 5,
    calVar: ["bundles", "bundlesAmount", "thickness", "width", "length"],
    cal: (val, id) => {
      var bundles = val[0];
      var bundlesAmount = val[1];

      return bundles * bundlesAmount;
    },
  },
  bdft: {
    key: "bdft",
    name: "Bdft",
    val: "",
    type: "cal",
    total: "yes",
    num: 6,
    calVar: ["thickness", "width", "length"],
    cal: (val, id) => {
      var thickness = val[0];
      var width = val[1];
      var length = val[2];

      return round((thickness * width * length) / 12);
    },
  },
  totalBdft: {
    key: "totalBdft",
    name: "Total Bdft",
    val: "",
    type: "cal",
    num: 7,
    calVar: [
      "qty",
      "bdft",
      "bundles",
      "bundlesAmount",
      "thickness",
      "width",
      "length",
    ],
    cal: (val, id) => {
      var qty = val[0];
      var bdft = val[1];

      return round(qty * bdft);
    },
  },
  mbft: {
    key: "mbft",
    name: "mbft",
    val: "",
    type: "input",
    num: 8,
    calVar: ["each", "bdft"],
    cal: (val, id) => {
      var each = val[0];
      var bdft = val[1];

      return round((1000 / bdft) * each);
    },
  },
  each: {
    key: "each",
    name: "$ EA",
    val: "",
    type: "input",
    num: 9,
    calVar: ["mbft", "bdft", "thickness", "width", "length"],
    cal: (val, id) => {
      var mbft = val[0];
      var bdft = val[1];

      return round((bdft / 1000) * mbft);
    },
  },
  cost: {
    key: "cost",
    name: "Cost",
    val: "",
    type: "cal",
    total: "yes",
    num: 10,
    calVar: [
      "mbft",
      "each",
      "totalBdft",
      "qty",
      "bdft",
      "bundles",
      "bundlesAmount",
      "thickness",
      "width",
      "length",
    ],
    cal: (val, id) => {
      var mbft = val[0];
      var each = val[1];
      var totalBdft = val[2];

      return round((totalBdft / 1000) * mbft);
    },
  },
};

