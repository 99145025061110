import { bundleSizes } from "./Data/bundleSizes";

import { obj } from "./Data/obj";

export var tableGen = {
  gen: (numRows) => {
    var headerCount = 0;
    var total = [];
    var headers = [];

    for (var key in obj) {
      headers.push({
        name: "Header",
        val: obj[key].name,
        type: "fix",
        x: 1,
        y: headerCount,
      });

      total.push({ name: "Total", val: "", type: "fix", x: 0, y: headerCount });
      headerCount++; //For the total calculator
    }

    var i = 0;
    var j = 0;

    var rows = [];
    var row = [];
    var objTemp = {};

    var keys;
    for (i = 0; i < numRows; i++) {
      for (keys in obj) {
        objTemp = { ...obj[keys] };
        objTemp.x = i + 2;
        objTemp.y = j;
        row.push(objTemp);
        j++;
      }
      rows.push(row);
      row = [];

      j = 0;
    }

    total[headerCount - 5] = {
      name: "Total",
      val: "Total",
      type: "fix",
      x: 0,
      y: headerCount - 5,
    };
    total[headerCount - 4] = {
      name: "Total",
      val: "",
      type: "cal",
      x: 0,
      y: headerCount - 4,
    };
    total[headerCount - 3] = {
      name: "Total",
      val: "",
      type: "cal",
      x: 0,
      y: headerCount - 3,
    };
    total[headerCount - 2] = {
      name: "Total",
      val: "",
      type: "",
      x: 0,
      y: headerCount - 2,
    };
    total[headerCount - 1] = {
      name: "Total",
      val: "",
      type: "cal",
      x: 0,
      y: headerCount - 1,
    };

    var table = [total, headers];
    table = table.concat(rows);
    
    //console.log(table);

    return table;
  },
};

