import "./style";
import style from "./style.css";
import Table from "./components/table";
import TablePlywood from "./components/tablePlywood";
import Draw from "./components/draw";
import Header from "./components/header";
import Info from "./components/info";
import Interpreter from "./components/interpreter";
import { useEffect, useState } from "preact/hooks";

export default function App() {
  const [inputData, setInputData] = useState();

  return (
    <div>
      <main>
        <div class="container">
          <Header />
          <Interpreter val={{ inputData, setInputData }} />
          <Table val={{ inputData, setInputData }} />
          <Info />
        </div>
      </main>
    </div>
  );
}


//figure out how to update now that the parse works!!
//Need to fix error where the mbft and each is not recalculating when changing T, W, H
//Need to add back in the plywood table - main focus on the lumber table
//Size of the row mins?? (48x48px)
//Need to add defination and caluclation for bft on the page, maybe below table

// need to fix 2x10s, 2x12s issue!!!
