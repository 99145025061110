import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import style from "./style.css";
import { lumberInterpreter } from "./lumberInterpreter";

const Interpreter = ({ val }) => {
  const [textField, setText] = useState("");

  function cellChange(event) {
    //example 2x6 8/10 10/12 12/14
    var id = event.target.id;
    var value = event.target.value;

    var obj = lumberInterpreter.parse(value);

    setText(value);
    val.setInputData(obj);

    //var obj = lumberInterpreter.parse("2x6 8/10 10/12 12/14 2x8 12/14");

    //val.setInputData(obj);
  }

  return (
    <div>
      <h1 class={style.header}>Lumber Interpreter</h1>
      <p class={style.subHeader}>
        Input lumber tally details and text below, and the tool will then
        convert the data (the best it can) into the table info below
      </p>
      <textarea onChange={cellChange}>{textField}</textarea>
      <p class={style.subHeaderRed}>
       Note changing this will delete table info!
      </p>
    </div>
  );
};

export default Interpreter;

